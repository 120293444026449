import React from "react"

// UI
import Card from "~components/Card"

export const TwoColCard: React.FC<ColCardProps> = ({
  className,
  children,
  noPadding = false,
  ...props
}: ColCardProps) => {
  return (
    <Card size="large" className={className} noPadding={noPadding} {...props}>
      {children}
    </Card>
  )
}

const SectionTwoCols: React.FC<SectionTwoColsProps> = ({
  className,
  children,
  narrow = false,
}: SectionTwoColsProps) => {
  const baseContainerClasses =
    "grid grid-cols-1 mx-auto px-6 lg:px-12 flex gap-4 md:gap-6 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-7xl"
  const defaultContainerClasses = "md:grid-cols-2"
  const narrowContainerClasses = "lg:grid-cols-2"

  return (
    <div
      className={`${baseContainerClasses} ${narrow ? narrowContainerClasses : defaultContainerClasses} ${className}`}
    >
      {children}
    </div>
  )
}

interface ColCardProps {
  children: React.ReactNode
  className?: string
  noPadding?: boolean
}

interface SectionTwoColsProps {
  children: React.ReactNode
  className?: string
  narrow?: boolean
}

export default SectionTwoCols
