import React from "react"
import Section from "./Section"

// UI
import Headline from "~ui/Headline"

const SectionMediaFull: React.FC<SectionMediaFullProps> = ({
  action,
  children,
  className,
  img,
  imgAlt,
  infoBar,
  narrow = false,
  subTitle,
  title,
  video,
  ...props
}: SectionMediaFullProps) => {
  const baseContainerClasses = "mx-auto sm:px-6 lg:px-12"
  const defaultContainerClasses = "sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-7xl"
  const narrowContainerClasses = "sm:max-w-xl md:max-w-2xl lg:max-w-2xl xl:max-w-4xl 2xl:max-w-6xl"

  return (
    <div className="container mx-auto">
      <div className={"px-6 lg:px-12 " + className} {...props}>
        {title && (
          <div
            className={`${baseContainerClasses} ${narrow ? narrowContainerClasses : defaultContainerClasses}`}
            data-sal="slide-up"
          >
            <div className="px-6 2xl:px-8">
              <Headline level={2}>{title}</Headline>
              {subTitle && (
                <p className="lg:text-lg lg:leading-relaxed dark:text-gray-300 mb-12 lg:mb-20">{subTitle}</p>
              )}
            </div>
          </div>
        )}
        <div className={"rounded-3xl overflow-hidden relative " + (title ? "mt-6 md:mt-10 lg:mt-14" : "")}>
          {video && <div className="bg-gray-800 aspect-w-16 aspect-h-9 ca-video-container">{video}</div>}
          {img && (
            <div className="bg-gray-800">
              <img src={img} alt={imgAlt} />
            </div>
          )}
          {action && <div className="absolute bottom-3 md:bottom-6 right-3 md:right-6">{action}</div>}
          {infoBar && (
            <div className="bg-blue-600 border border-t-0 border-blue-500 rounded-br-3xl rounded-bl-3xl">
              <div className="mx-auto sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-7xl px-6 lg:px-12">
                <div className="lg:px-6 2xl:px-8">{infoBar}</div>
              </div>
            </div>
          )}
        </div>
        {children && (
          <Section className="mt-12 lg:mt-20" data-sal="slide-up">
            {children}
          </Section>
        )}
      </div>
    </div>
  )
}

interface SectionMediaFullProps {
  action?: React.ReactNode
  animationDelay?: number
  children?: React.ReactNode
  className?: string
  img?: string
  imgAlt?: string
  infoBar?: React.ReactNode
  narrow?: boolean
  subTitle?: string | React.ReactNode
  title?: string | React.ReactNode
  video?: React.ReactNode
}

export default SectionMediaFull
