import React from "react"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

// UI
import SectionFourCols, { FourColCard } from "~components/SectionFourCols"
import {
  faCreditCard,
  faShoppingBag,
  faBalanceScaleRight,
  faBrowser,
  faHandshake,
  faCar,
  faStore,
  faPlusCircle,
} from "@fortawesome/pro-thin-svg-icons"

export const defaultIndustriesList = [
  {
    title: "Neobanks",
    icon: faCreditCard,
    delay: "0",
  },
  {
    title: "Retail",
    icon: faShoppingBag,
    delay: "100",
  },
  {
    title: "Legal",
    icon: faBalanceScaleRight,
    delay: "200",
  },
  {
    title: "SaaS platforms",
    icon: faBrowser,
    delay: "300",
  },
  {
    title: "Business loans",
    icon: faHandshake,
    delay: "0",
  },
  {
    title: "Gig economy",
    icon: faCar,
    delay: "100",
  },
  {
    title: "Marketplace",
    icon: faStore,
    delay: "200",
  },
]

export type TUseCaseIndustriesProps = {
  industries: { title: string; icon: IconProp; delay: string }[]
}

const UseCaseIndustries: React.FC<TUseCaseIndustriesProps> = ({ industries }) => {
  return (
    <div className="container mx-auto">
      <SectionFourCols
        title="Loan Servicing Designed for Any Industry"
        body="A flexible construct designed perfectly for both point-of-sale and online lenders. Whether it's home
    improvement, general health care or other opportunities, Canopy allows you to design a suite of products to
    accommodate both lender and distribution partner needs."
        className="mb-20 md:mb-40"
      >
        {industries.map((i) => (
          <FourColCard title={i.title} icon={i.icon} key={i.title} delay={i.delay} />
        ))}
        <FourColCard title="And more..." icon={faPlusCircle} delay="400" />
      </SectionFourCols>
    </div>
  )
}

export default UseCaseIndustries
