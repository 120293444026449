import * as React from "react"

const Section = (props: SectionProps): JSX.Element => {
  return (
    <div
      className={`mx-auto sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-7xl px-12 ${props.className}`}
    >
      <div className="lg:px-6 2xl:px-8">{props.children}</div>
    </div>
  )
}

interface SectionProps {
  children: React.ReactNode
  className?: string
}

export default Section
