import React from "react"

const Card: React.FC<CardProps> = ({
  className,
  size,
  children,
  noPadding = false,
  justify = "center",
  ...props
}: CardProps) => {
  const CardBaseClasses = "rounded-3xl flex flex-col"
  const CardLargeClasses = "md:px-12 xl:px-16 2xl:px-24 py-10 md:py-16 lg:py-16"
  const CardPaddingClasses = "px-6 2xl:px-8 py-8"

  return (
    <div
      className={`${CardBaseClasses} justify-${justify} ${noPadding ? "" : CardPaddingClasses} ${
        !noPadding && size === "large" ? CardLargeClasses : ""
      } ${className}
      `}
      {...props}
    >
      {children}
    </div>
  )
}

interface CardProps {
  children?: React.ReactNode
  className?: string
  justify?: string
  size?: "large"
  noPadding?: boolean
}

export default Card
