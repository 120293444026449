import React, { useState } from "react"

// UI
import Card from "~components/Card"
import Headline from "~ui/Headline"
import SectionTwoCols, { TwoColCard } from "~components/SectionTwoCols"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"
import { faArrowDown, faArrowUp } from "@fortawesome/pro-light-svg-icons"

import communityImg from "../../images/use-case/community.png"

const questions = [
  {
    k: 1,
    q: "How can existing data be migrated?",
    a: "Canopy has a migration mode so you can safely bring data from your existing System of Record to Canopy. Simply toggle migration mode on before starting your data migration, and turn it back off once your migration is complete.",
  },
  {
    k: 2,
    q: "Does Canopy support converting a revolving product into a term loan product?",
    a: "Canopy allows a user to run an introductory revolving promotion prior to rolling your account's activity into an installment loan. At the end of the revolving period, you can roll all of the account's balance into a loan which will amortize over a period defined by you with policies defined by you.",
  },
  {
    k: 3,
    q: "Can Canopy support payment processing?",
    a: "Yes, there is no need to set up a totally separate payment processing system in addition to using Canopy.  Canopy has popular integrations already built to handle money movement on behalf of your borrowers.",
  },
  {
    k: 4,
    q: "Does Canopy integrate with Customer Support platforms like Zendesk or Freshdesk?",
    a: "Yes, there are a few ways: 1) Data in Canopy can be accessed via the API surfaced in your existing applications. 2) Use webhooks to subscribe to events directly in Canopy for consumption by your external systems. 3) Canopy has existing integrations, for example, Zendesk where we have built an application that can be loaded into your Zendesk environment.",
  },
  {
    k: 5,
    q: "Does Canopy support webhooks?",
    a: "Yes, a user can subscribe to events directly in Canopy for consumption by your external systems. You can get be notified when statements are generated in real-time!",
  },
]

const FaqItem: React.FC<TFaqItemProps> = ({ children, className, title, key }) => {
  const [isActive, setIsActive] = useState(false)

  return (
    <div className={`border-b border-gray-200 ${className}`} key={key}>
      <div
        className={`relative pl-6 pr-12 py-3 cursor-pointer hover:text-blue-500 font-semibold text-black transition duration-200 ease-in ${
          isActive && "bg-gray-50"
        }`}
        onClick={() => setIsActive(!isActive)}
      >
        {title}
        <div className="absolute top-3 right-5 text-gray-400">
          {!isActive ? <Icon icon={faArrowDown} /> : <Icon icon={faArrowUp} />}
        </div>
      </div>
      {isActive && (
        <div className="px-6 py-3">
          <p className="text-gray-700">{children}</p>
        </div>
      )}
    </div>
  )
}

const UseCaseCommunity: React.FC = () => {
  return (
    <SectionTwoCols>
      <TwoColCard className="px-6 md:px-0 mb-20 lg:mb-16" noPadding>
        <img
          src={communityImg}
          className="mx-auto mb-4"
          style={{ width: "100%", maxWidth: 604 }}
          alt="code"
          data-sal="slide-up"
        />
        <Headline level={3} className="md:text-4xl mb-6 sm:text-center" data-sal="slide-up" data-sal-delay="100">
          Canopy is Community
        </Headline>
        <p
          className="md:w-4/5 mx-auto sm:text-center lg:text-lg"
          data-sal="slide-up"
          data-sal-delay="200"
          style={{ maxWidth: 604 }}
        >
          We understand the importance of community and real-time communication. We build real relationships with our
          clients and cherish our growing community.
        </p>
      </TwoColCard>
      <TwoColCard className="sm:px-6 md:px-12 xl:px-16 2xl:px-24" data-sal="slide-up" data-sal-delay="300" noPadding>
        <Headline level={3} className="px-6 md:text-4xl mb-6">
          FAQs
        </Headline>
        <Card className="bg-white shadow-2xl overflow-hidden" noPadding>
          {questions.map((question) => (
            <FaqItem title={question.q} key={question.k}>
              {question.a}
            </FaqItem>
          ))}
        </Card>
      </TwoColCard>
    </SectionTwoCols>
  )
}

interface TFaqItemProps {
  children: React.ReactNode
  className?: string
  title?: string | React.ReactNode
  key: number | string
}

export default UseCaseCommunity
