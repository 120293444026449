import React from "react"

// UI
import Button from "~ui/Button"
import SectionMediaFull from "~components/SectionMediaFull"
import SectionTwoCols, { TwoColCard } from "~components/SectionTwoCols"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faCircleCheck } from "@fortawesome/pro-light-svg-icons"

// Media
import imgPreviewAccountDetail from "~images/platform/preview-account-detail.png"

const UseCaseServicing: React.FC<TUseCaseServicingProps> = ({ bullet1, bullet2, bullet3 }) => (
  <SectionMediaFull
    title={
      <>
        <span className="block text-base md:text-lg text-blue-500 mb-2">CanopyOS</span>An Operating System for
        Redefining Loan Management
      </>
    }
    img={imgPreviewAccountDetail}
    action={
      <Button to="/platform" size="large" style="secondary" icon={faArrowRight}>
        Learn More
      </Button>
    }
    className="mb-20 md:mb-40"
  >
    <SectionTwoCols className="xl:-mx-6">
      <TwoColCard className="mb-2 md:mb-0 md:px-6 xl:px-16 2xl:px-24" noPadding>
        <ul className="lg:text-lg">
          <li className="mb-3">
            <Icon icon={faCircleCheck} size="lg" className="text-blue-500 mr-3" />
            <strong className="text-black dark:text-white">{bullet1}</strong>
          </li>
          <li className="mb-3">
            <Icon icon={faCircleCheck} size="lg" className="text-blue-500 mr-3" />
            <strong className="text-black dark:text-white">{bullet2}</strong>
          </li>
          <li>
            <Icon icon={faCircleCheck} size="lg" className="text-blue-500 mr-3" />
            <strong className="text-black dark:text-white">{bullet3}</strong>
          </li>
        </ul>
      </TwoColCard>
      <TwoColCard className="md:px-6 xl:px-16 2xl:px-24" noPadding>
        <p className="lg:text-lg">
          Canopy&lsquo;s servicing platform provides your operations and CSR teams with the tools to quickly access and
          edit account information, including status, balances, transaction history, servicing history, statements, and
          other key account data.
        </p>
      </TwoColCard>
    </SectionTwoCols>
  </SectionMediaFull>
)

export type TUseCaseServicingProps = {
  bullet1: string
  bullet2: string
  bullet3: string
}

export default UseCaseServicing
