import React from "react"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

// UI
import Headline from "~ui/Headline"
import Card from "~components/Card"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"

export const FourColCard: React.FC<ColCardProps> = ({ className, delay, key, title, icon, children }: ColCardProps) => {
  const colCardBaseClasses = "bg-gray-50 dark:bg-gray-900 text-center "

  return (
    <Card className={colCardBaseClasses + className} key={key} data-sal="slide-up" data-sal-delay={delay}>
      <div>
        <Icon icon={icon} size="3x" className="mb-6 text-blue-500" />
      </div>
      <Headline level={5}>{title}</Headline>
      {children}
    </Card>
  )
}

const SectionFourCols: React.FC<SectionFourColsProps> = ({
  className,
  title,
  body,
  children,
}: SectionFourColsProps) => {
  const SectionFourColsBaseClasses = "mx-auto sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-7xl px-0"

  return (
    <div className={`${SectionFourColsBaseClasses} ${className}`}>
      <div>
        {title && (
          <Headline level={2} data-sal="slide-up">
            {title}
          </Headline>
        )}
        {body && (
          <p className="lg:text-lg md:mb-6 max-w-4xl 2xl:max-w-5xl" data-sal="slide-up" data-sal-delay="100">
            {body}
          </p>
        )}
      </div>

      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-6 pt-8 md:pt-12">{children}</div>
    </div>
  )
}

interface ColCardProps {
  children?: React.ReactNode
  className?: string
  delay?: string
  title: string | React.ReactNode
  icon?: IconProp
  key?: number | string
}

interface SectionFourColsProps {
  children: React.ReactNode
  className?: string
  title?: string | React.ReactNode
  body?: string
}

export default SectionFourCols
