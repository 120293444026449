import React from "react"

import Layout from "~components/layout"
import SEO from "~components/seo"
import UseCaseHero from "~content/use-case/Hero"
import UseCaseServicing from "~content/use-case/Servicing"
import UseCaseIndustries, { defaultIndustriesList } from "~content/use-case/Industries"
import Community from "~content/use-case/Community"

import heroImage from "~images/use-case/charge-cards-hero.png"

const UseCaseChargeCardsPage: React.FC = () => {
  return (
    <Layout>
      <SEO
        canonicalTag="https://canopyservicing.com/use-case/payment-cards"
        description="Launch and service custom payment card programs and meet the unique needs of individual users and organizations."
        title="Payment Cards | Canopy Servicing"
      />
      <UseCaseHero
        title="Payment Cards"
        body="Create a charge card product for any industry with multiple program variations."
        image={heroImage}
      />
      <UseCaseServicing
        bullet1="Supports risk-based scoring"
        bullet2="Deferred interest capabilities"
        bullet3="Define promo periods"
      />
      <UseCaseIndustries industries={defaultIndustriesList} />
      <Community />
    </Layout>
  )
}

export default UseCaseChargeCardsPage
