import React from "react"

// UI
import Button from "~ui/Button"
import Headline from "~ui/Headline"
import SectionTwoCols, { TwoColCard } from "~components/SectionTwoCols"
import Spirograph from "~components/artwork/Spirograph"
import Aura from "~components/artwork/Aura"
import { faArrowRight } from "@fortawesome/pro-light-svg-icons"

export type TUseCaseHeroProps = {
  title: string
  body: string
  image: string
}

const UseCaseHero: React.FC<TUseCaseHeroProps> = ({ title, body, image }) => {
  return (
    <>
      <SectionTwoCols className="py-12 sm:py-20 lg:py-28 lg:mb-8">
        <TwoColCard className="px-6 md:px-12 xl:px-16 2xl:px-24 mb-4 md:mb-0" noPadding>
          <Headline level={1} data-sal="slide-up" data-sal-delay="200">
            <span className="block text-base md:text-lg text-blue-500 mb-2">Canopy is made for</span> {title}
          </Headline>
          <p className="lg:text-lg" data-sal="slide-up" data-sal-delay="300">
            {body}
          </p>
          <div
            className="flex flex-col sm:flex-row md:flex-col lg:flex-row mt-8 lg:mt-10"
            data-sal="slide-up"
            data-sal-delay="400"
          >
            <Button
              style="primary"
              to="/start"
              icon={faArrowRight}
              size="large"
              className="mb-2 sm:mb-0 sm:mr-4 md:mb-2 md:mr-0 lg:mb-0 lg:mr-4"
            >
              Start Now
            </Button>
            <Button to="/platform" size="large">
              Learn More
            </Button>
          </div>
        </TwoColCard>
        <TwoColCard className="2xl:px-12" data-sal="slide-up" data-sal-delay="600" noPadding>
          <img src={image} alt="Canopy Product Constructs" />
        </TwoColCard>
      </SectionTwoCols>

      <Spirograph
        shape={2}
        width="w-full1/2x sm:w-full1/2x md:w-full1/4x xl:w-3/4"
        position="right-0"
        offsetY="-translate-y-full md:-translate-y-2/3 lg:-translate-y-3/5 xl:-translate-y-1/2 2xl:-translate-y-2/5"
        offsetX="translate-x-1/2 md:translate-x-3/4 lg:translate-x-3/5 xl:translate-x-1/2"
      />
      <Spirograph
        shape={7}
        width="w-0 md:w-24"
        position="right-0"
        offsetY="md:-translate-y-1/10 2xl:-translate-y-1/20"
        offsetX="md:translate-x-1/4 xl:-translate-x-full"
      />
      <Aura
        color="purple"
        size="w-full2x lg:w-full"
        position="left-0"
        offsetY="-translate-y-1/5"
        offsetX="-translate-x-1/2"
        opacity="opacity-20"
      />
      <Aura
        size="w-full2x sm:w-full1/2x md:w-full"
        position="right-0"
        offsetY="-translate-y-7/10"
        offsetX="translate-x-1/2"
        opacity="opacity-50"
      />
      <Aura
        color="purple"
        size="w-full1/2x md:w-full"
        position="right-0"
        offsetY="-translate-y-2/5"
        offsetX="translate-x-3/5"
        opacity="opacity-30"
      />
    </>
  )
}

export default UseCaseHero
